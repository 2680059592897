import React, { useEffect, useState } from 'react';


function ErrorHandling (error) {
  if (process.env.FLASK_ENV === 'development') {
    if (error.response) {
      // Request made and server responded
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error occurs on the backend :', error.message);
    }
  } else {
    console.log('Error occurs on the backend :', error.message);
  }
}

export default ErrorHandling;