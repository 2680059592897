import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import { createTheme } from '@material-ui/core';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const darkTheme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#A66025'
        },

    }
});

const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#4169E1'
        // main: '#FDB716'
        },

    }
});

class Root extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            themeColor: lightTheme,
        };
        this.getTheme = this.getTheme.bind(this);
    }

    getTheme(type) {
        if (type === 'dark') {
            this.setState({
                themeColor: darkTheme,
            });
        } else {
            this.setState({
                themeColor: lightTheme,
            });
        }
    }

    render() {
        return (
            <ThemeProvider theme={this.state.themeColor}>
                <App getTheme={this.getTheme} />
            </ThemeProvider>
        )
    }
}

document.body.style.overflow = "hidden";
ReactDOM.render(
    <Root />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
