import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';


const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));


function sigFigs(n, sig) {
  if (Number(n) === n && n % 1 !== 0) {
    if (n < 0) {
      var m = n * -1.0;
    } else {
      var m = n;
    }
    var mult = Math.pow(10, sig - Math.floor(Math.log(m) / Math.LN10) - 1);
    var o = Number(Math.round(m * mult) / mult).toPrecision(sig + 1);
    if (n < 0) {
      return o * -1.0;
    } else {
      return o;
    } 
  }
  return n;
}

export default function FisherStatTable( { tableData } ) {

  const classes = useStyles();
  const sigFigsN = 5;
  const cols = ['Cohort contrast'].concat(tableData['columns']);
  const rows = tableData['data'].map( (row, i) => {
    if (row[0] === null || row[0] === 0) {
      row[0] = 'NA';
    } else {
      row[0] = sigFigs(row[0], sigFigsN);
    }
    row[1] = sigFigs(row[1], sigFigsN);
    row[2] = sigFigs(row[2], sigFigsN);
    return [ tableData.index[i][1], ...row ] 
  });

  return (
    <React.Fragment>
      <Title>Fisher Test Results</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {
              cols.map((col, i) => 
                (i === cols.length - 1) ? <TableCell align="right">{col}</TableCell> : <TableCell>{col}</TableCell>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.map((row, i) => (
              <TableRow key={i}>
                {
                  row.map((elem, j) => 
                    (j === row.length - 1) ? <TableCell align="right">{elem}</TableCell> : <TableCell>{elem}</TableCell>
                  )
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
