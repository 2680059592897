import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Title from './Title';

import notFound from '../assets/not_found_image.png';


const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

// https://pubchem.ncbi.nlm.nih.gov/compound/<cid>

const DrugAnnotation = ({ drugInfo, drugImg }) => {
  // {
  //   name: '',
  //   cid: '', 
  //   molecular_weight: '', 
  //   isomeric_smiles: '', 
  //   molecular_formula: '', 
  //   iupac_name: ''
  // }

  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Compound</Title>
      { 
        drugInfo.name && 
        <Typography>{drugInfo.name}</Typography>
      }
      { 
        drugInfo.cid && 
        <Link 
          href={`https://pubchem.ncbi.nlm.nih.gov/compound/${drugInfo.cid}`} 
          target="_blank"
          variant="body2"
          >{`${drugInfo.iupac_name}`}
        </Link>
      }
      <div>
        {
          (drugImg.length !== 0) ? <img src={drugImg} alt="Image Not Found" /> : <img src={notFound} alt="Image Not Found" />
        }
      </div>
      {
        drugInfo.cid &&
        <Typography variant="body2" gutterBottom>
          {<p><b>SMILES:</b>  {drugInfo.isomeric_smiles}</p>}
          {<p><b>MOL FORMULA:</b>  {drugInfo.molecular_formula}</p>}
          {<p><b>MOL WEIGHT:</b>  {drugInfo.molecular_weight}</p>}
        </Typography>
      }
    </React.Fragment>
  );
}

export default DrugAnnotation;