import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function AboutDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("body");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button size="large" color="inherit" onClick={handleClickOpen("body")}>About</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">ABOUT</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {<p><a href="https://www.auransa.com" target="_blank">Auransa</a> is providing this Covid 19 data app 
            for public access to support its publication in the journal 
            of <a href="https://www.nature.com/srep/" target="_blank">Scientific Reports</a>.</p>}

            {<p>Please cite the paper as: "Shen, Z., Halberg, A., Fong, J.Y. , Guo, J. et al. 
              Elucidating host cell response pathways and repurposing therapeutics for 
              SARS-CoV-2 and other coronaviruses. Sci Rep 12, 18811 (2022).".</p>}

            {<p>The peer-reviewed paper can be accessed 
              through <a href="https://doi.org/10.1038/s41598-022-21984-w" target="_blank">this link</a>.</p>}

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}