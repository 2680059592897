import React from 'react';
import Plot from "react-plotly.js";


const SnazzyViolin = ({ id, drug, cohortContrast, drugRawData, contrastRawData }) => {
  // drugRawData and contrastRawData are just list of floats

  var data = [{
    type: 'violin',
    x: Array(drugRawData.length).fill(drug),
    y: drugRawData,
    points: 'none',
    box: {
      visible: true
    },
    line: {
      color: 'light blue',
    },
    meanline: {
      visible: true
    },
    showlegend: false
  }, {
    type: 'violin',
    x: Array(contrastRawData.length).fill('contrast background (sampled)'),
    y: contrastRawData,
    points: 'none',
    box: {
      visible: true
    },
    line: {
      color: 'grey',
    },
    meanline: {
      visible: true
    },
    showlegend: false
  }]
  
  var layout = {
    autosize: false,
    width: 600,
    height: 500,
    title: {
      text: cohortContrast,
      font: {
        size: 16,
        color: '#4169E1'
      }
    },
    xaxis: {
      tickfont: {
        size: 12,
        color: '#4169E1'
      }
    },
    yaxis: {
      title: {
         text: 'Reversal Strength Score',
         font: {
          size: 12,
          color: '#4169E1'
        }
      },
      range: [-2.2, 2.2],
      zeroline: false
    }
  }

  return (
    <Plot id={id} data={data} layout={layout} />
  );

}

export default SnazzyViolin;
