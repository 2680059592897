import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import ErrorHandling from '../utils/ErrorHanding';
import backendClient from '../backendClient';


function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}


function logoutBackend() {
  const config = {
    headers:{
      'X-CSRF-TOKEN': getCookie('csrf_access_token')
    }
  };
  return backendClient.post(`/logout`, {}, config)
    .then(response => response.data)
    .catch(ErrorHandling);
 }


export default function UserLogout({ setLoginStatus }) {

  const handleLogout = async () => {
    const payload = await logoutBackend();
    if (payload) {
      setLoginStatus(payload.login);
    }
  }

  return (
    <div>
      <Button size="large" color="inherit" onClick={handleLogout}>Logout</Button>
    </div>
  );
}

UserLogout.propTypes = {
  setLoginSuccess: PropTypes.func.isRequired
};