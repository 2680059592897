import * as React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import eAddrPic from '../assets/request_email.png'; 


export default function LoginTerms() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Box textAlign='center'>
        <Button size='small' color='primary' onClick={handleClickOpen('body')}>Access Request and Privacy Statement</Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>Access Request and Privacy Statement</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {<p><a href="https://www.auransa.com" target="_blank">Auransa</a> is providing this app for the purpose of 
            supporting the <a href="https://doi.org/10.1038/s41598-022-21984-w" target="_blank">publication</a> in the 
            journal of <a href="https://www.nature.com/srep/" target="_blank">Scientific Reports</a>.</p>}

            {<p><b>Access Request: </b>  To access this app, the user must accept the Privacy Statement below and 
            request a user passcode by writing to <img src={eAddrPic} alt="covidapp@auransa.com" width="200" height="23" />.</p>}

            {<p><b>Privacy Statement: </b>  Auransa gathers only necessary information related to the usage and security of 
            this website (“User Data”) for the purpose of providing this web service. The company does not distribute or sell User Data. </p>}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}