import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


const DrugSearch = ({ drugs, onChange }) => {
  return (
    <div>
      <Autocomplete
        freeSolo
        id="input-search-drug"
        disableClearable
        options={drugs}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for a drug"
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      />
    </div>
  );
}

export default DrugSearch;
