import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const styles = {
  root: {
    
  },
  paper: {
    padding: '10px',
    position: 'absolute',
    overflowY: 'auto',
    overflowX: 'hidden',
    minWidth: 16,
    minHeight: 16,
    maxWidth: 600, // 'calc(100vw - 640px)'
    maxHeight: 400
  }
}


function HelpButtonPopover(props) {
  const { classes, color, size, content } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <span>
      <IconButton aria-describedby={id} variant="contained" onClick={handleClick} color={ color || 'default' } size={ size || 'small'}>
        <HelpOutlineOutlinedIcon fontSize='small'/>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.paper }}
      >
        <Typography align='left' fontSize='8' variant='body2'>{ content || '<Please Add Popover Content.>' }</Typography>
      </Popover>
    </span>
  );
}

HelpButtonPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(HelpButtonPopover);