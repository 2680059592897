import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function Disclaimer() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("body");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button size="large" color="inherit" onClick={handleClickOpen("body")}>DISCLAIMER</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">DISCLAIMER</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {<p>Auransa (“Company") gathers only necessary information related to the usage and security of 
              this website (“User Data”) by the users (“Users”) for the purpose of providing this service ("Service”). 
              The Company does not distribute or sell User Data.</p>}

            {<p>The Company has made every effort to provide accurate information and data served from 
              this website ("Service Data”). Nevertheless, inadvertent errors in information and data may occur. 
              All Service Data is provided as-is without warranty for accuracy or completeness. By using this website, 
              Users agree to indemnify the Company from any damage or liability that may arise from using the Service or 
              the Service Data. The Company reserves the right to terminate or change the behavior of this Service anytime 
              without notice.</p>}

            {<p>This website is made available to the public by the Company without any financial cost to Users. 
              Users assume all responsibility and risk for the use of this site. The Company disclaims any liability 
              for any improper or incorrect use of the information contained on this site and assumes no responsibility 
              for anyone's use of the information. In no event shall the Company be liable for any direct, indirect incidental, 
              special or consequential damages however caused and on any theory of liability, whether in contract, strict liability, 
              or tort (including negligence or otherwise) arising in any way from the use or inability to use the site and/or 
              any other websites which are linked to this site. This disclaimer of liability applies to any damages or injuries, 
              including but not limited to those caused by any failure of performance, error, omission, interruption, deletion, 
              defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or 
              unauthorized access to, alteration of, or use of record, whether for breach of contract, tortious behavior, negligence 
              or any other cause of action.</p>}

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}