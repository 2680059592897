import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import AboutDialog from './AboutDialog';
import Disclaimer from './Disclaimer';
import UserLogout from './UserLogout';
import backendClient from '../backendClient';
import ErrorHandling from '../utils/ErrorHanding';
import DrugSearch from './DrugSearch';
import HelpButtonPopover from './HelpButtonPopover';
import FisherStatTable from './FisherStatTable';
import DrugAnnotation from './DrugAnnotation';
import SnazzyViolin from './SnazzyViolin';
import logo from '../assets/auransa.png';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.auransa.com/">
        Auransa Inc. 
      </Link>{' All Rights Reserved. '}
      {new Date().getFullYear()}
    </Typography>
  );
}


const popOverTextBlobs = {
  'menu_select': 'Please use the `search for a drug` with the autocomplete function to find a drug of interest; \
                  Then click the menu items the multiple selection box to choose several cohort contrasts; After selection, click on \
                  `Show Data` button to display the data.',
}

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 240,
    maxWidth: 400,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 300,
    },
  },
};

function getMulSelectStyles(name, contrastName, theme) {
  return {
    fontWeight:
      contrastName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function Dashboard({ setLoginStatus }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);  
  const [drugOfInterest, setDrugOfInterest] = useState('');
  const [contrastsOfInterest, setContrastsOfInterest] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [cohortContrasts, setCohortContrasts] = useState([]);
  const [fisherData, setFisherData] = useState({});
  const [drugContrastSnazzyValues, setDrugContrastSnazzyValues] = useState([]);
  const [contrastSnazzyValues, setContrastSnazzyValues] = useState([]);
  const [drugInfo, setDrugInfo] = useState({
    name: '',
    cid: null, 
    molecular_weight: null, 
    isomeric_smiles: null, 
    molecular_formula: null, 
    iupac_name: null
  });
  const [drugImg, setDrugImg] = useState([])
  const [loaded, setLoaded] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  useEffect(() => {
    backendClient.get(`/drugs`)
      .then(response => setDrugs(response.data))
      .catch(ErrorHandling);
  }, []); // no reload of genes after loading the app

  useEffect(() => {
    backendClient.get(`/cohort_contrasts`)
      .then(response => setCohortContrasts(response.data))
      .catch(ErrorHandling);
  }, []); // no reload of cohort contrasts after loading the app


  const onDrugOfInterestChange = (event, newValue) => {
    setLoaded(false);
    setDrugOfInterest(newValue);
    setDrugImg([]);
    backendClient.get(`/drug/${newValue}`)
      .then(res => {
        setDrugInfo(res.data);
        if ('cid' in res.data && Number.isInteger(parseInt(res.data['cid']))) {
          backendClient.get(`/drug_img/${res.data['cid']}`)
            .then(res2 => setDrugImg(res2.data))
            .catch(ErrorHandling);
        } 
      })
      .catch(ErrorHandling);
  };

  const onCohortContrastsSelect = (event) => {
    setLoaded(false);
    setContrastsOfInterest(event.target.value);
  };

  const fetchData = () => {
    // http://localhost:5000/fisher_stats?selected_drug=proxyphylline&contrasts=GSE1739_SARS-CoV-1_na_PBMC&contrasts=GSE17400_SARS-CoV-1_24h_HAE
    const contrastParams = contrastsOfInterest.map(contrast => `contrasts=${contrast}`)
    var queryParamStr = [`selected_drug=${drugOfInterest}`, ...contrastParams].join('&')
    const fisherDataPromise = () => {
      return backendClient.get(`/fisher_stats?${queryParamStr}`)
        .then(response => setFisherData(response.data))
        .catch(ErrorHandling);
    };
    
    const allSnazzyRawDataPromises = contrastsOfInterest.map(contrast => {
      // http://localhost:5000/raw_scores?selected_drug=proxyphylline&contrast=GSE1739_SARS-CoV-1_na_PBMC
      return backendClient.get(`/raw_scores?selected_drug=${drugOfInterest}&contrast=${contrast}`)
        .then(response => response.data)
        .catch(ErrorHandling);
    });

    const allContrastDataPromises = contrastsOfInterest.map(contrast => {
      // http://localhost:5000/background_raw_scores?contrast=GSE1739_SARS-CoV-1_na_PBMC
      return backendClient.get(`/background_raw_scores?contrast=${contrast}`)
        .then(response => response.data)
        .catch(ErrorHandling);
    });

    const snazzyDataPromise = Promise
      .all(allSnazzyRawDataPromises)
      .then((results) => setDrugContrastSnazzyValues(results));

    const contrastDataPromise = Promise
      .all(allContrastDataPromises)
      .then((results) => setContrastSnazzyValues(results));

    const setLoadedPromise = () => {
      setLoaded(true);
    };
    
    Promise.resolve()
      .then(snazzyDataPromise)
      .then(contrastDataPromise)
      .then(fisherDataPromise)
      .finally(setLoadedPromise);
  };

  const onShowButtonClick = (event) => {
    setLoaded(false);
    setFisherData({});
    setDrugContrastSnazzyValues([]);
    setContrastSnazzyValues([]);
    if ( contrastsOfInterest.length > 0 && drugOfInterest !== '') {
      if (!loaded) {
        fetchData();
      }
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <a href='https://www.auransa.com/'><img src={logo} alt='Logo' className={classes.appBarIcon} width="36" height="36" style={{marginRight: '0.5em'}}/></a>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            AURANSA COVID19 APP
          </Typography>
          <AboutDialog />
          <Disclaimer />
          <UserLogout setLoginStatus={setLoginStatus} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <div>
          { open && 
            <div mx="auto">
              <FormControl className={classes.formControl}>
                <DrugSearch drugs={drugs} onChange={onDrugOfInterestChange}/>
              </FormControl>
            </div>
          }
          { open && 
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="select-multiple-contrast">Select Cohort Contrasts</InputLabel>
                <Select
                  labelId="select-multiple-contrast"
                  id="mutiple-name"
                  // native
                  multiple
                  value={contrastsOfInterest}
                  onChange={onCohortContrastsSelect}
                  input={<Input />}
                  inputProps={{
                    id: 'select-multiple-contrast',
                  }}
                  MenuProps={MenuProps}
                >
                {cohortContrasts.map((contrast) => (
                  <MenuItem key={contrast} value={contrast} style={getMulSelectStyles(contrast, contrastsOfInterest, theme)}>
                    {contrast}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </div>
          }
        </div>
        <div>
          { open && 
            <div className={classes.formControl}>
              <Button variant="outlined" onClick={onShowButtonClick}>Show Data</Button>
              <HelpButtonPopover content={popOverTextBlobs.menu_select}></HelpButtonPopover>
            </div>
          }
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={2}>
            {/* Compound info */}
            {
              drugInfo.name !== '' && 
              <Grid item xs={12}  md={6} lg={6} >
                <Paper className={classes.paper}>
                  <DrugAnnotation drugInfo={drugInfo} drugImg={drugImg}/>
                </Paper>
              </Grid>
            }
            {/* Violin plots */}
            {
              loaded && drugContrastSnazzyValues.length > 0 && contrastSnazzyValues.length > 0 && contrastsOfInterest.map((contrast, i) => (
                <Grid item xs={12} md={6} lg={6}>
                  <Paper>
                    <SnazzyViolin id={`violin-${i}`} drug={drugOfInterest} cohortContrast={contrast} 
                      drugRawData={drugContrastSnazzyValues[i]['snazzy']['data']} contrastRawData={contrastSnazzyValues[i][contrast]} />
                  </Paper>
                </Grid>
              ))
            }
            {/* FDR data table */}
            {
              loaded && fisherData['columns'] && fisherData['data'] &&
              <Grid item xs={12}  md={6} lg={6} >
                <Paper className={classes.paper}>
                  <FisherStatTable tableData={fisherData} />
                </Paper>
              </Grid>
            }
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
