import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import { Grid,Paper, Avatar, TextField, Button, Typography, Link } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';

import ErrorHandling from '../utils/ErrorHanding';
import LoginGuide from './LoginTerms';


function loginByPasscode(passcode) {
  const baseURL = process.env.REACT_APP_BACKEND_URL || '/backend';
  return axios.post(`${baseURL}/login`, {
      user_uuid: passcode
    })
    .then(response => response.data)
    .catch(ErrorHandling);
 }


export default function UserLogin({ setLoginStatus }) {
  const [passcode, setPasscode] = useState();
  const [loginErrMsg, setLoginErrMsg] = useState('');

  const paperStyle={
    padding: 30,
    height: 280, // '35vh',
    width: 340,
    margin:"20px auto"
  }
  const avatarStyle={
    backgroundColor: '#4169E1'  // '#1bbd7e'
  }
  const btnstyle={
    margin:'8px 0'
  }
  const onPasscodeChange = (event) => {
    setLoginErrMsg('');
    setPasscode(event.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const payload = await loginByPasscode(passcode);
    if (payload) {
      setLoginStatus(payload.login);
    } else {
      setLoginErrMsg('Error in Login.')
    }
  }

  return(
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid align='center'>
            <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
            <Typography><h3>Log In</h3></Typography >
        </Grid>
        <form className="form" onSubmit={handleSubmit}>
          <TextField label='User Passcode' placeholder='Enter user passcode' variant="outlined" fullWidth required onChange={onPasscodeChange}/>
          <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>Submit</Button>
          <Typography >
            <LoginGuide />
          </Typography>
          <Typography align='center' color='secondary'>
            {loginErrMsg}
          </Typography>
        </form>
      </Paper>
    </Grid>
  )
}


UserLogin.propTypes = {
  setLoginSuccess: PropTypes.func.isRequired
};