import React, { useState, useEffect } from 'react';
import Dashboard from './components/Dashboard';
import UserLogin from './components/UserLogin';
import backendClient from './backendClient';


export default function App() {
  const [message, setMessage] = useState('');
  const [loginStatus, setLoginStatus] = useState(false);

  function fetchMessage() {
    return backendClient.get('/hello/world')
      .then((response) => {
        setMessage(response.data);
      })
      .catch((error) => console.log('Error occurs on the backend :', error.message));
  }

  function validateJWT() {
    return backendClient.get('/jwt_validation')
      .then((response) => {
        if (response) {
          setLoginStatus(response.data.login);
        } else {
          setLoginStatus(false);
        }
      })
      .catch((error) => console.log('Error occurs on the backend :', error.message));
  }

  useEffect(fetchMessage, []);
  useEffect(validateJWT, []);

  return (
    <div>
      {
        loginStatus ? <Dashboard setLoginStatus={setLoginStatus} />
          : <UserLogin setLoginStatus={setLoginStatus} />
      }
    </div>
  )
}
